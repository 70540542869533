<template>
  <!-- Table Container Card -->
  <div class="table-content">
    <b-table
      :items="items"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="`등록된 ${target}이 없습니다.`"
      class="position-relative"
    >
      <template v-slot:empty="scope">
        <div class="my-3 mx-2 text-center">
          {{ scope.emptyText }}
        </div>
      </template>

      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ minWidth: '120px' }"
        >
      </template>

      <!-- Column: name -->
      <template #cell(name)="data">
        <b-link
          style="text-decoration: underline"
          class="font-weight-bold text-nowrap"
          @click="moveTo(data.item)"
        >
          {{ data.value }}
        </b-link>
      </template>

      <!-- Column: Profit -->
      <template #cell(profit)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Modified Date -->
      <template #cell(modifiedAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Created At -->
      <template #cell(createdAt)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: User Status -->
      <template #cell(status)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

    </b-table>
  </div>
</template>

<script>
import { BTable, BLink } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { computed } from '@vue/composition-api'
import useDate from '@/views/utils/useDate'
import router from '@/router'
import { adminRouteName } from '@/router/routes/admin'
import useNumber from '@/views/utils/useNumber'

export default {
  components: {
    BTable,
    BLink,
  },
  props: {
    target: {
      type: String,
      default: '채널계정',
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { parseDateTable, parseDateDeep } = useDate()
    const { toMoney } = useNumber()
    const { t } = useI18nUtils()

    const tableColumns = computed(() => [
      { key: 'name', label: `${props.target}명` },
      { key: 'profit', label: props.target === '채널계정' ? t('Profit') : '지출', formatter: toMoney },
      { key: 'createdAt', label: t('EnrolledAt'), formatter: val => parseDateTable(val) },
      { key: 'modifiedAt', label: t('ModifiedAt'), formatter: val => parseDateDeep(val) },
      { key: 'status', label: `${props.target} 상태`, formatter: val => t(`USER_${val}`) },
    ])

    const moveTo = item => {
      if (props.target === '채널계정') {
        router.push({ name: adminRouteName.channelAccountView, params: { channelId: item.id } })
      } else {
        router.push({ name: adminRouteName.adAccountView, params: { adId: item.id } })
      }
    }

    return {
      tableColumns,
      moveTo,
    }
  },
}
</script>
