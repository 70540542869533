/* eslint-disable object-curly-newline */
import { reactive, toRefs } from '@vue/composition-api'

export default function useMember() {
  const currentMember = toRefs(reactive({
    id: '',
    name: '',
    username: '',
    mobile: '',
    roles: '',
    status: '',
    channelAccounts: [],
    adAccounts: [],
  }))

  const setCurrentMember = member => {
    currentMember.id.value = member.id
    currentMember.name.value = member.name
    currentMember.username.value = member.username
    currentMember.mobile.value = member.mobile
    currentMember.roles.value = member.roles
    currentMember.status.value = member.status
    currentMember.channelAccounts.value.push(...member.channelAccounts)
    currentMember.adAccounts.value.push(...member.adAccounts)
  }

  const makeData = () => ({
    id: currentMember.id.value,
    name: currentMember.name.value,
    username: currentMember.username.value,
    mobile: currentMember.mobile.value,
    roles: currentMember.roles.value,
    status: currentMember.status.value,
  })

  return {
    currentMember,
    setCurrentMember,
    makeData,
  }
}
