<template>
  <b-card>
    <loading-component
      v-if="loading"
    />
    <error-component
      v-else-if="error"
      :error="error"
    />
    <div v-else>
      <!-- 계정 정보 -->
      <b-row
        class="mb-3"
        align-v="center"
      >
        <!-- Name -->
        <b-col cols="2">
          <b-form-input
            v-model="name"
            readonly
          />
        </b-col>

        <!-- Email -->
        <b-col cols="3">
          <b-form-input
            v-model="username"
            readonly
          />
        </b-col>

        <!-- Mobile -->
        <b-col cols="3">
          <b-form-input
            v-model="mobile"
            readonly
          />
        </b-col>

        <!-- Role -->
        <b-col cols="3">
          <b-form-radio-group
            v-model="roles"
            class="d-flex flex-wrap"
            :options="roleOptions"
            name="radios-btn-default"
            @change="setUserRole"
          />
        </b-col>
      </b-row>

      <account-list-table
        class="mb-4"
        target="채널계정"
        :items="channelAccounts"
      />

      <account-list-table
        class="mb-3"
        target="광고계정"
        :items="adAccounts"
      />

      <!-- submit button -->
      <b-row
        class="mx-0 mb-1"
        align-h="between"
      >
        <b-button
          class="submit-btn"
          variant="primary"
          @click="goBack"
        >
          목록
        </b-button>
        <b-button
          v-b-modal.modal-alert-user-status
          class="submit-btn"
          variant="primary"
        >
          {{ isActivated ? '비활성화 하기' : '활성화 하기' }}
        </b-button>
      </b-row>

      <!-- modal status alert -->
      <b-modal
        id="modal-alert-user-status"
        :title="`${name}님의 아이디를 ${isActivated ? '비활성화' : '활성화'} 하시겠습니까?`"
        :ok-title="`${isActivated ? '비활성화' : '활성화'} 하기`"
        cancel-title="취소"
        ok-variant="primary"
        cancel-variant="outline-secondary"
        centered
        hide-header-close
        @ok="toggleState"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, VBModal, BCol, BFormRadioGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { computed, onMounted } from '@vue/composition-api'
import router from '@/router'
import { adminRouteName } from '@/router/routes/admin'
import useAdminTask from '@/views/utils/task/useAdminTask'
import useMember from '@/views/utils/member/useMember'
// eslint-disable-next-line import/named
import { useRouter } from '@core/utils/utils'
import AccountListTable from '../../../components/table/tables/AccountListTable.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    AccountListTable,
    BCol,
    BFormInput,
    BFormRadioGroup,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const {
      currentMember,
      setCurrentMember,
      makeData,
    } = useMember()

    const {
      loading,
      error,
      fetchMember,
      updateMember,
    } = useAdminTask()

    const { route } = useRouter()

    const {
      name,
      username,
      mobile,
      status,
      roles,
      channelAccounts,
      adAccounts,
    } = currentMember

    const toggleState = async () => {
      if (currentMember.status.value === 'ACTIVATED') {
        currentMember.status.value = 'DISABLED'
      } else {
        currentMember.status.value = 'ACTIVATED'
      }
      const member = makeData()
      await updateMember(member)
      router.go()
    }

    const setUserRole = async () => {
      const member = makeData()
      await updateMember(member)
      router.go()
    }

    const goBack = () => router.push({ name: adminRouteName.members })

    // 컴포넌트 마운트 시 데이터 fetch
    onMounted(async () => {
      const { memberId } = route.value.params
      const member = await fetchMember(memberId)
      setCurrentMember(member)
    })

    const isActivated = computed(() => status.value === 'ACTIVATED')

    const roleOptions = [
      {
        text: '회원',
        value: 'ROLE_USER',
      },
      {
        text: '관리자',
        value: 'ROLE_ADMIN',
      },
    ]

    return {
      loading,
      error,
      name,
      username,
      mobile,
      status,
      isActivated,
      roles,
      channelAccounts,
      adAccounts,
      goBack,
      setUserRole,
      toggleState,
      roleOptions,
    }
  },
}
</script>

<style lang="scss">
.account-form-wrapper {
  padding-right: 50px;
  padding-left: 50px;
  h3 {
    margin-top: 50px;
  }

  .input-title {
    font-size: large;
    margin-top: 20px;
    margin-bottom: 10px;

    span {
      display: inline-flex;
      align-items: center;
    }
  }

  .submit-btn {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
.table-content{
  text-align: center;
}

.modal-header {
  border-bottom: 0 none;
}

.modal-footer {
  border-top: 0 none;
}
</style>
