import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useAdminTask() {
  const loading = ref(false)
  const error = ref(null)

  const fetchMember = async id => {
    loading.value = true
    return store.dispatch('admin/fetchMember', { id })
      .then(response => {
        const { member } = response.data.payload
        return member
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const updateMember = async member => {
    loading.value = true
    return store.dispatch('admin/updateMember', member)
      .then(response => {
        const { newMember } = response.data.payload
        return newMember
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchSlotList = async (queryString, start, end) => {
    loading.value = true
    return store.dispatch('admin/fetchSlotList', { q: queryString ?? '', start, end })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchCampaignList = async (queryString, start, end) => {
    loading.value = true
    return store.dispatch('admin/fetchCampaignList', { q: queryString ?? '', start, end })
      .then(response => {
        const { campaigns } = response.data.payload
        return campaigns
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchCampaign = async id => {
    loading.value = true
    return store.dispatch('admin/fetchCampaign', id)
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.message
      }).catch(e => {
        error.value = e
      }).finally(() => {
        loading.value = false
      })
  }

  const reviewCampaign = async (campaign, review) => {
    loading.value = true
    return store.dispatch('campaign/reviewCampaign', {
      campaign,
      review,
    }).then(response => {
      if (response.data.status === 2000) {
        return response.data.payload
      }
      throw response.data.message
    })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const fetchSlot = async id => {
    loading.value = true
    return store.dispatch('admin/fetchSlot', id)
      .then(response => {
        const { slot } = response.data.payload
        return slot
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  const reviewSlot = async (slot, review) => {
    loading.value = true
    return store.dispatch('slot/reviewSlot', { slot, review })
      .then(response => {
        if (response.data.status === 2000) {
          return response.data.payload
        }
        throw response.data.payload
      })
      .catch(e => {
        error.value = e
      })
      .finally(() => {
        loading.value = false
      })
  }

  return {
    loading,
    error,
    fetchMember,
    updateMember,
    fetchSlotList,
    fetchSlot,
    reviewSlot,
    fetchCampaignList,
    fetchCampaign,
    reviewCampaign,
  }
}
